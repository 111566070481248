import React from 'react';

import styled from 'styled-components';

import { shuffle } from '@/c/utils/datas';

interface Props {
    children: React.ReactNode;
    backgroundColor: string;
}

const MagicCard: React.FC<Props> = (p) => {
    return (
        <Wrapper>
            <div
                style={{
                    backgroundColor: p.backgroundColor,
                }}
                className={'card-content'}
            >
                {p.children}
            </div>
        </Wrapper>
    );
};
export default MagicCard;

const Wrapper = styled.div`
    position: relative;
    margin: 1rem auto;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    ${(props) => props.theme.mediaQuery.min.lg`max-width: 75%;`}

    --border-width: 1px;
    border-radius: var(--border-width);

    .card-content {
        margin: var(--border-width);
        padding: 16px;
    }

    img {
        width: 90%;
        max-width: 300px;
        height: 250px;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: calc(-1 * var(--border-width));
        left: calc(-1 * var(--border-width));
        width: calc(100% + var(--border-width) * 2);
        height: calc(100% + var(--border-width) * 2);
        // ${(props) => randomBackground()};
        background: linear-gradient(
            45deg,
            #fb0094,
            #0000ff,
            #00ff00,
            #ffff00,
            #ff0000,
            #fb0094,
            #0000ff,
            #00ff00,
            #ffff00,
            #ff0000
        );
        background-size: 400%;
        z-index: -1;
        animation: steam 80s linear infinite;
    }

    &:after {
        filter: blur(10px);
    }

    @keyframes steam {
        0% {
            background-position: 0 0;
        }
        50% {
            background-position: 400% 0;
        }
        100% {
            background-position: 0 0;
        }
    }
`;

function randomBackground(): string {
    const colors = ['#0000ff', '#00ff00', '#ffff00', '#ff0000', '#fb0094', '#0000ff', '#00ff00', '#ffff00', '#ff0000'];

    return `
        background: linear-gradient(45deg, ${shuffle(colors).join(',')})
    `;
}
