import React from 'react';

import { Button } from '@mui/material';
import styled from 'styled-components';

import Modal, { ModalProps } from '@/c/components/molecules/Modal/Modal';
import { defaultModal } from '@/c/components/molecules/Modal/styles';

export type JoinWaitingListModalI18n = {
    l0: string;
    l1: string;
    sendEmailBtn: string;
};
interface Props extends ModalProps {
    i18n: JoinWaitingListModalI18n;
    to: string;
}

const JoinWaitingListModal: React.FC<Props> = ({ i18n, to, ...p }) => {
    const subject = 'Join';

    return (
        <StyledModal {...p}>
            <p>{i18n.l0}</p>
            <p style={{ textAlign: 'center' }}>{to}</p>
            <p>{i18n.l1}</p>
            <a
                style={{
                    textDecoration: 'none',
                }}
                href={`mailto:${to}?subject=${subject}`}
            >
                <Button
                    style={{
                        margin: '1rem 0',
                        width: '100%',
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    {i18n.sendEmailBtn}
                </Button>
            </a>
        </StyledModal>
    );
};
export default JoinWaitingListModal;

const StyledModal = styled(Modal)<ModalProps>`
    ${defaultModal}
`;
