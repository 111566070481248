import styled, { css } from 'styled-components';

const common = css`
    font-weight: 700;
    text-align: center;
    font-size: 40px;
    font-family: Hack, sans-serif;
    text-transform: uppercase;
    letter-spacing: 5px;
    background: linear-gradient(90deg, #000, #fff, #000);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
`;

const ShinningText = styled.p`
    ${common}
    background-size: 80%;
    animation: shine 5s linear infinite;
    position: relative;

    @keyframes shine {
        0% {
            background-position-x: -500%;
        }
        100% {
            background-position-x: 500%;
        }
    }
`;

export default ShinningText;

export const OneTimeShinningText = styled.p`
    ${common}
    background-size: 80%;
    animation: one-shine 5s linear 1;
    position: relative;
    animation-fill-mode: forwards;

    @keyframes one-shine {
        0% {
            background-size: 80%;
            background-position-x: -500%;
        }
        100% {
            background-size: 100%;
            background-position-x: 25%;
        }
    }
`;
