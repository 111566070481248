import React from 'react';

import { isApiLoggedIn } from '@/c/http/api';
import { croutes } from '@/c/routes';
import { useRouter } from '@/core/router';

import { getRouteSource } from '../getRouteSource';

export default function withClientAllowForGuestOnly<P>(
    WrappedComponent: React.ComponentType<P>,
    ref?: string
): (props: P) => null | JSX.Element {
    const withClientAllowForGuestOnly = (props: P) => {
        if (typeof window !== 'undefined') {
            const Router = useRouter();
            if (isApiLoggedIn()) {
                Router.replace(
                    getRouteSource({
                        pathname: croutes.ROUTINES,
                        query: {
                            ref: ref,
                        },
                    })
                );
                return null;
            }
            return <WrappedComponent {...props} />;
        }

        // If we are on server, return null
        return null;
    };

    withClientAllowForGuestOnly.WrappedComponent = WrappedComponent;

    return withClientAllowForGuestOnly;
}
