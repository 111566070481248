import React from 'react';

import { randomString } from '@hapi/cryptiles';
import styled, { createGlobalStyle } from 'styled-components';

import assetsResolver from '@/c/assetsResolver';
import LogoImage from '@/c/components/molecules/LogoImage';
import FocusText from '@/c/components/pages/IndexPage/FocusText';
import GlowingButton from '@/c/components/pages/IndexPage/GlowingButton';
import JoinWaitingListModal, { JoinWaitingListModalI18n } from '@/c/components/pages/IndexPage/JoinWaitingListModal';
import MagicCard from '@/c/components/pages/IndexPage/MagicCard';
import { OneTimeShinningText } from '@/c/components/pages/IndexPage/ShinningText';
import { JOIN_BETA_EMAIL } from '@/c/constants/brand';
import useModalContext from '@/c/contexts/Modal/useModalContext';
import { croutes } from '@/c/routes';
import Link from '@/core/Link/Link';

export type IndexPageI18n = {
    login: string;
    headers: string[];
    achieveDeuslibra: string;
    subHeaders: string[];
    joinWaitingListModalI18n: JoinWaitingListModalI18n;
    joinTheBetaBtn: string;
};
interface Props {
    i18n: IndexPageI18n;
}

const GlobalStyle = createGlobalStyle`
    body {
        background: black;
    }
`;
const IndexPage: React.FC<Props> = ({ i18n }) => {
    const modalContext = useModalContext();

    return (
        <Wrapper>
            <GlobalStyle />
            <div className={'header'}>
                <LogoImage />
                <Link name={croutes.LOGIN}>{i18n.login}</Link>
            </div>
            <div>
                {i18n.headers.map((e, i) => (
                    <h1 key={i}>{e}</h1>
                ))}
                {/*<WithAssets src={RequiredAssets}>*/}
                {/*    {{*/}
                {/*        placeholder: (*/}
                {/*            <div*/}
                {/*                style={{*/}
                {/*                    height: '200px',*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        ),*/}
                {/*        content: (*/}
                {/*            <ParticleTextEffect*/}
                {/*                text={'ACHIEVE DEUSLIBRA!'}*/}
                {/*                backgroundColor={0x0}*/}
                {/*                color={0xddaaff}*/}
                {/*                heightInPx={200}*/}
                {/*            />*/}
                {/*        ),*/}
                {/*    }}*/}
                {/*</WithAssets>*/}
                <FocusText style={{ margin: '4rem 0' }}>{i18n.achieveDeuslibra}</FocusText>
                {i18n.subHeaders.map((e, i) => (
                    <OneTimeShinningText key={i}>{e}</OneTimeShinningText>
                ))}
            </div>
            <div className={'action-btn-container'}>
                <GlowingButton
                    style={{ margin: '2rem auto 2rem auto' }}
                    onClick={() => {
                        modalContext.showModal(JoinWaitingListModal, {
                            i18n: i18n.joinWaitingListModalI18n,
                            to: JOIN_BETA_EMAIL,
                        });
                    }}
                >
                    {i18n.joinTheBetaBtn}
                </GlowingButton>
            </div>
            <div className={'benefits'}>
                <MagicCard backgroundColor={'rgb(26 54 55)'}>
                    <AnimatedImage
                        src={assetsResolver.image('static/index/sport-silhouette-physical-fitness.png')}
                        alt={''}
                        duration={4}
                        points={[
                            {
                                p: 0,
                                degree: 0,
                            },
                            {
                                p: 35,
                                degree: 2.5,
                            },
                            {
                                p: 70,
                                degree: -2.5,
                            },
                            {
                                p: 100,
                                degree: 0,
                            },
                        ]}
                    />
                </MagicCard>
                <MagicCard backgroundColor={'rgb(32 32 32)'}>
                    <AnimatedImage
                        src={assetsResolver.image('static/index/yoga-silhouette.png')}
                        alt={''}
                        duration={6}
                        points={[
                            {
                                p: 0,
                                scale: 0.5,
                            },
                            {
                                p: 50,
                                scale: 1,
                            },
                            {
                                p: 100,
                                scale: 0.5,
                            },
                        ]}
                    />
                </MagicCard>
                <MagicCard backgroundColor={'rgb(52 0 0)'}>
                    <AnimatedImage
                        src={assetsResolver.image('static/index/male-handstand-silhouette-wellness.png')}
                        alt={''}
                        points={[
                            {
                                p: 0,
                                degree: 0,
                            },
                            {
                                p: 35,
                                degree: 10,
                            },
                            {
                                p: 70,
                                degree: -10,
                            },
                            {
                                p: 100,
                                degree: 0,
                            },
                        ]}
                    />
                </MagicCard>
                <MagicCard backgroundColor={'rgb(33 24 54)'}>
                    <AnimatedImage
                        src={assetsResolver.image('static/index/female-silhouette-working-out.png')}
                        alt={''}
                        duration={4}
                        points={[
                            {
                                p: 0,
                                translateX: -50,
                            },
                            {
                                p: 50,
                                translateX: 80,
                                rotateY: 180,
                            },
                            {
                                p: 100,
                                translateX: -50,
                            },
                        ]}
                    />
                </MagicCard>
                <MagicCard backgroundColor={'rgb(7 7 7)'}>
                    <img src={assetsResolver.image('static/index/mobile-site-screenshoot.png')} alt={''} />
                </MagicCard>
            </div>
        </Wrapper>
    );
};
export default IndexPage;

export const Wrapper = styled.div`
    padding: 1rem 0.8rem;
    min-height: 100vh;
    height: 100%;
    color: white;
    font-family: 'Poppins', sans-serif;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        a {
            font-family: Hack, sans-serif;
            text-decoration: none;
        }
    }

    h1 {
        text-align: center;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 1.5em;
        letter-spacing: 0.1em;
        color: #fff;

        font-family: Hack, sans-serif;
        text-transform: uppercase;
        background: linear-gradient(90deg, #000, #fff, #000);
        letter-spacing: 5px;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-repeat: no-repeat;
        background-size: 90%;
        animation: shine 5s linear infinite;
        position: relative;

        @keyframes shine {
            0% {
                background-position-x: -500%;
            }
            100% {
                background-position-x: 500%;
            }
        }
    }

    p {
        text-align: center;
        font-size: 1.1rem;
        font-weight: 600;
        letter-spacing: 0.1em;
        margin-top: 40px;
        color: #e7e3e3;
    }
`;

const AnimatedImage = styled.img<{
    duration?: number;
    points: {
        p: number;
        degree?: number;
        scale?: string | number;
        rotateX?: number;
        rotateY?: number;
        translateX?: number;
        translateY?: number;
    }[];
}>`
    ${({ duration, points }) => {
        let c = '';

        for (const point of points) {
            const r = [];
            let transform = '';

            if (point.degree !== undefined) {
                transform = `transform: rotate(${point.degree}deg)`;
            }

            if (point.scale !== undefined) {
                const t = `scale(${point.scale})`;
                if (transform === '') {
                    transform = `transform: ${t}`;
                } else {
                    transform += ` ${t}`;
                }
            }

            if (point.translateX !== undefined) {
                const t = `translateX(${point.translateX}px)`;
                if (transform === '') {
                    transform = `transform: ${t}`;
                } else {
                    transform += ` ${t}`;
                }
            }

            if (point.translateY !== undefined) {
                const t = `translateY(${point.translateY}px)`;
                if (transform === '') {
                    transform = `transform: ${t}`;
                } else {
                    transform += ` ${t}`;
                }
            }

            if (point.rotateX !== undefined) {
                const t = `rotateX(${point.rotateX}deg)`;
                if (transform === '') {
                    transform = `transform: ${t}`;
                } else {
                    transform += ` ${t}`;
                }
            }

            if (point.rotateY !== undefined) {
                const t = `rotateY(${point.rotateY}deg)`;
                if (transform === '') {
                    transform = `transform: ${t}`;
                } else {
                    transform += ` ${t}`;
                }
            }

            r.push(`${transform}`);

            c += `${point.p}% {
                ${r.join(';\n')} 
            }`;
        }
        const animationName = `irotate-${randomString(8)}`;

        return `
            animation: ${animationName} ${duration ?? 2}s linear infinite;
            @keyframes ${animationName} {
                ${c}
            }
    `;
    }}
`;
