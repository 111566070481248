import React, { ButtonHTMLAttributes } from 'react';

import styled from 'styled-components';

const GlowingButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = (p) => {
    return <ButtonWrapper {...p} />;
};
export default GlowingButton;

const ButtonWrapper = styled.button`
    display: flex;
    height: fit-content;
    z-index: 0;
    padding: 15px 20px;
    font-family: monospace;
    color: white;
    font-weight: bold;
    font-size: 1.4rem;
    transition: all 500ms ease-out;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    border-radius: 10px;

    &:before {
        content: '';
        border-radius: 10px;
        background: linear-gradient(45deg, #ff0000, #002bff, #7a00ff, #ff0000);
        position: absolute;
        top: 0;
        left: 0;
        background-size: 400%;
        z-index: -1;
        filter: blur(2.5px);
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        animation: glowing 20s linear infinite;
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }
    &:hover:before {
        animation-play-state: paused;
    }

    @keyframes glowing {
        0% {
            background-position: 0 0;
        }
        50% {
            background-position: 400% 0;
        }
        100% {
            background-position: 0 0;
        }
    }
`;
