import React, { useLayoutEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import useWindowDimensions from '@/c/hooks/useWindowDimensions';

interface Props {
    children: string;
    style?: React.CSSProperties;
}

const FocusText: React.FC<Props> = (p) => {
    const targetRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const windowDimensions = useWindowDimensions();

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
            });
        }
    }, []);

    const componentSize = dimensions.width === 0 ? windowDimensions.width : dimensions.width;
    const wp = {
        ...p,
        componentSize: `${componentSize}px`,
        maskSize: `${componentSize * 0.3}px`,
        borderOffset: '10px',
        backgroundColor: 'rgb(203 102 179 / 21%)',
        animationProps: '2.5s linear infinite alternate',
        text: p.children,
    };

    return (
        <Wrapper {...wp}>
            <div className="focus">
                <div className="before">{p.children}</div>
                <div className="focus--mask">
                    <div className="focus--mask-inner">{p.children}</div>
                </div>
            </div>
        </Wrapper>
    );
};
export default FocusText;

function pxToNr(val: string): number {
    return parseInt(val.replace('px', ''));
}

const Wrapper = styled.div<{
    componentSize: string;
    maskSize: string;
    borderOffset: string;
    backgroundColor: string;
    animationProps: string;
    text: string;
}>`
    ${({ componentSize, maskSize, borderOffset, backgroundColor, animationProps }) => `
    
       @keyframes mask-move {
            0% {
                transform: translateX(0);
                filter: blur(2.25px);                
            }
            
            50% {
                filter: blur(0.25px);                
            }
            
            100% {
                transform: translateX(${pxToNr(componentSize) - pxToNr(maskSize)}px);
                filter: blur(2.25px);
            }
        }

        @keyframes mask-inner-move {
            0% {
                transform: translateX(0);
            }
            100% {
                transform: translateX(-(${pxToNr(componentSize) - pxToNr(maskSize)}px));
            }
        }
        
    .focus {
        font-family: Hack, sans-serif;     
        font-size: 2rem;
        text-transform: uppercase;
        color: white;
        letter-spacing: 0.2rem;
        line-height: 1;
        position: relative;
        width: ${componentSize};
    
        .before {
            filter: blur(2.25px);
            text-align: center;
        }

        &:after {
            content: '';
            position: absolute;
            width: ${maskSize};
            height: calc(100% + ${borderOffset});
            top: -${pxToNr(borderOffset) / 2}px;
            left: 0;
            border-top: 2px solid;
            border-bottom: 2px solid;
            animation: mask-move ${animationProps};
        }
        &--mask {
            overflow: hidden;
            position: absolute;
            width: ${maskSize};
            height: 100%;
            top: 0;
            left: 0;
            background: ${backgroundColor};
            animation: mask-move ${animationProps};
            &-inner {
                animation: mask-inner-move ${animationProps};
            }
        }
    }
    `}
`;
