import { css } from 'styled-components';

export const defaultModal = css`
    .v-modal {
        display: flex;
        ${(props) => props.theme.mediaQuery.min.lg`max-width: 640px;`}
        max-width: 90%;
        max-height: 98%;
        border-radius: 10px;
        margin: 10px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        overflow-y: auto;
        flex-direction: column;
    }

    .v-modal-body {
        position: relative;
        text-align: center;
        padding: 10px 10px 20px 10px;
        color: #444444;
        font-size: 1.4rem;
    }
`;
