import React from 'react';

import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { SSRConfig, withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

import IndexPage, { IndexPageI18n } from '@/c/components/pages/IndexPage/IndexPage';
import { LayoutProps } from '@/c/components/templates/types';
import { DEFAULT_LOCALE } from '@/c/constants/locales';
import { TFunction } from '@/c/translations/types';
import { scopedT } from '@/c/translations/utils';

import { NextPageProps } from '../types/NextPageProps';
import withClientAllowForGuestOnly from '../utils/withClientAllowForGuestOnly';

const T_NAMESPACE = 'index-page';

const Page = ({ t }: NextPageProps) => {
    const nt = scopedT(t, T_NAMESPACE);

    return (
        <>
            <Head>
                <title>{nt('pageTitle')}</title>
                <meta name="keywords" content={nt('metaKeywords')} />
            </Head>
            <IndexPage i18n={getTranslations(t)} />
        </>
    );
};
Page.getLayoutProps = {
    customMain: true,
    header: false,
} as LayoutProps;

const tNamespaces = ['common', 'header', 'footer', T_NAMESPACE];
export async function getStaticProps({ locale }: GetStaticPropsContext): Promise<GetStaticPropsResult<SSRConfig>> {
    return {
        props: {
            ...(await serverSideTranslations(locale || DEFAULT_LOCALE, tNamespaces)),
        },
    };
}

export default withClientAllowForGuestOnly(withTranslation(tNamespaces)(Page));

function getTranslations(t: TFunction): IndexPageI18n {
    const nt = scopedT(t, T_NAMESPACE);

    return {
        login: nt('login'),
        headers: nt('headers', {
            returnObjects: true,
        }),
        achieveDeuslibra: nt('achieveDeuslibra'),
        subHeaders: nt('subHeaders', {
            returnObjects: true,
        }),
        joinWaitingListModalI18n: nt('joinWaitingListModalI18n', {
            returnObjects: true,
        }),
        joinTheBetaBtn: nt('joinTheBetaBtn'),
    };
}
